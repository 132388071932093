import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import ProductInfo from "../ProductInfo/ProductInfo";
import LocationMap from "../../../images/location-map.jpg";
import LocationMapTablet from "../../../images/location-map-tablet.jpg";
import Map from "../../map/google/single"
import Calculator from "./Calculator";
import "./ProductContent.scss"
import CurrConvertLayout from "../../currency-converter/CurrConvertLayout";
import { CurrConvertPrice } from "../../currency-converter/CurrConvertPrice";
import { BedIcon, BathIcon, SizeIcon } from "../../Icons/Icons";
import ReadMoreComponent from "../../ReadMore/ReadMore";
import _ from "lodash"
import PropertyPermit from "./PropertyPermit";
import PropertyAreaGuide from "./PropertyAreaGuide";
//import ScrollAnimation from "react-animate-on-scroll";
// styles

// markup

const ProductContent = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [current, setCurrent] = useState('');
  const handleCurrConvert = (childData) =>{
    setCurrent(childData)
  }

  const getPOA = (data) => {
    var permit = "";
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }

  if(typeof window !== 'undefined') {
    localStorage.setItem('propertyPrice_cal', parseFloat(props.data.price));
    console.log("propertyPrice calc",localStorage.getItem('propertyPrice_cal'));
    localStorage.setItem('propertyPrice', CurrConvertPrice(props.data.price.toLocaleString()).slice(3));
    console.log("propertyPrice",localStorage.getItem('propertyPrice'));
  }
  var address = "";
  if(props?.data){
    if(props?.data?.address?.county){
      address += props?.data?.address.Address+", ";
    }
    if(props?.data?.address?.area){
      address += props?.data?.address.area+", ";
    }
    if(props?.data?.address?.town){
      address += props?.data?.address.town;
    }
  }

  var floor_area = props.data.floor_area ? " "+props.data.floor_area+" sq ft" : "" 
  // var h1_addr = _.capitalize(props.data.title) + " in " +_.capitalize(address) + floor_area;
  var h1_addr = _.capitalize(props.data.title) + " in " +_.capitalize(address);

  const longDescription = props.data.long_description;

  const formattedContent = longDescription
  .split(/<br\s*\/?>/i)
  .map(paragraph => `<p>${paragraph.trim()}</p>`)
  .join('')
  .replace(/<p><\/p>/g, '');
  return (

    <React.Fragment>
      <div className="property-content-wrapper">
        <Container>
          <Row>
            <Col xl={8} className="property-left">
              <div className="property-content">                
                
                <div className="price-wrapper">
                  {props.data && props.data.price ?
                    getPOA(props.data.extras) == "" ?
                      <span className="product-price h3">
                        {CurrConvertPrice(props.data.price.toLocaleString()).slice(3)} {(props.data.search_type == "lettings") ? 'per year' : null}
                      </span>
                      : <span className="product-price h3">{getPOA(props.data.extras)}</span>
                    : ''
                  }              
                  <CurrConvertLayout parentCallback = {handleCurrConvert} outterClass="currency-select" />
                </div>
                { props?.data?.introtext && 
                <div className="property-intro-text">
                   <span className="text-md-bold">{props?.data?.introtext}</span>
                </div> 
                } 
                {h1_addr ?  
                 <h1 className="property-address text-xs">{h1_addr}</h1>   
                 :
                 <h1 className="property-address text-xs">{ props.data.title}  {address} </h1>   
                }            
                  {/* {props.data && props.data?.title &&
                        <div className="property-title text-sm-bold"> { props.data.title}{h1_addr}</div>
                  }
                {address && 
                  <div className="property-address text-xs">
                                      <span><i className="icon-location"></i></span>
                                        <span className="location-info text-xs">
                                        {address}
                                        </span>
                  </div>          
                }             */}
               <div className="property-meta-info">
               {(props.data && props.data.department == "residential" && props.data.bedroom) ?
                  <div className="property-beds meta-item">
                    <span className="meta-icon"><BedIcon /></span>                  
                    <span class="text-sm-bold meta-info">
                      {props.data.bedroom}&nbsp;<span className="d-none d-md-flex">Bedrooms</span><span className="d-flex d-md-none">Beds</span>
                    </span>
                  </div> :
                  ""        
                }
                {(props.data && props.data.department == "residential" && props.data.bathroom) ?
                  <div className="property-bath meta-item">
                  <span className="meta-icon"><BathIcon /></span>                  
                  <span class="text-sm-bold meta-info">
                  {props.data.bathroom}&nbsp;<span className="d-none d-md-flex">Bathrooms</span><span className="d-flex d-md-none">Baths</span></span>
                </div>:
                  ""      
                }
                {(props.data && props.data.floor_area) ?
                 <div className="property-size meta-item">
                 <span className="meta-icon"><SizeIcon /></span>                  
                 <span class="text-sm-bold meta-info">
                 {props.data.floor_area}&nbsp;<span>sq.ft</span></span>
                  </div>:
                  ""      
                } 
               </div>
              
              </div>
              { <div className="properties-icons">
                {props.data.crm_id &&
                  <span className="ref-number">
                    Ref.No : {props.data.crm_id}
                  </span>
                }
              </div>

              // <div className="properties-icons">
              //   {props.data.extras.permit_number != "" &&
              //     <span className="bedroom-number">
              //       Permit No : {props.data?.extras?.permit_number}
              //     </span>
              //   }
              // </div> 
              }
                {props.data && props.data.accomadation_summary &&
                    <div className="key-feature-block">
                      <h6>Key Features</h6>
                      <ul className="key-feature-list">
                        {props.data && props.data.accomadation_summary.map((data, i) => {
                          if (data != "") {
                            return (<li className="text-sm"> <span className="list">{data}</span> </li>)
                          } 
                        })}
                      </ul>
                    </div>
                }
                {props.data && formattedContent &&
                    <div className="property-description-block">
                      <h6>Description</h6>
                        <ReadMoreComponent content={formattedContent} maxlength={300} wrapclass="readmore-wrap text-sm" />
                      {/* {props.data.long_description.substring(0,4).toLowerCase() === "<br>" ? HTMLReactParser(props.data.long_description).slice(4) : HTMLReactParser(props.data.long_description)} */}
                    </div>
                }

                {/*  <div animateIn="fadeInUp" animateOnce>
                    <div className="property_amenity_block property-details-block">
                      <h2>Property Amenities</h2>
                      <ul>
                        <li>
                          <i className="icon-balcony"></i> Balcony

                        </li>
                        <li>
                          <i className="icon-pool"></i> Shared Pool

                        </li>
                        <li>
                          <i className="icon-gym"></i> Shared Gymcc

                        </li>
                        <li>
                          <i className="icon-security"></i> Security

                        </li>
                      </ul>
                    </div>
</div> */}

                {props.data.latitude ?
                    <div className="location-block">
                      <h6>Location</h6>
                      <div className="location-block-map">
                        <Map image={props.data.images} lat={props.data.latitude ? parseFloat(props.data.latitude) : ''} lng={props.data.longitude ? parseFloat(props.data.longitude) : ''} height={"460px"} zoom={5} OfficeName={props.data.title} OfficeAddress={props.data.address?.area} className="map-wrapper" />
                      </div>
                  </div> : ''
                }
                {props?.data?.address?.area &&
                  <PropertyAreaGuide area={props?.data?.address?.area} />
                }
                {
                  props.data.search_type == "sales" &&
                  <Calculator price={CurrConvertPrice(props.data.price.toLocaleString()).slice(3).replace(/,/g, '')} extras={props.data.extras} current={current}/>
                }
                <PropertyPermit permitNumber={props?.data?.extras?.permit_number} zoneName={props?.data?.qrData?.zoneName} qrCode={props?.data?.qrData?.qrCode} TrakheesiLink={props?.data?.qrData?.PermitLink}/>
            </Col>
            
            <Col xl={4}>
              <div className="product-details-sidebar">
                <ProductInfo data={props.data} handleCurrConvert={handleCurrConvert}/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductContent